#profile-page {
    background-color: white;
    position: absolute;
    width: 100%;
    // height: calc(100% - 135px);
    padding-bottom: 100px;

    .header2 {
        background-color: #009e87;
        line-height: 0.82;
        letter-spacing: 1.1px;
        text-align: center;
        color: #ffffff;
        font-size: 22px;
        padding: 15px;

        img {
            margin-right: 10px;
        }
    }

    .container {
        margin-top: 40px;
        width: 500px;

        .avatar-group {
            text-align: center;
            margin-bottom: 20px;
            img {
                background-color: #97b0c6;
                border-radius: 65px;
            }
        }
        .change-password {
            width: 100%;
            height: 45px;
            background-color: #009e87;
            border-radius: 4px;
            border: none;
            margin-top: 20px;
        }
    }
}

@media (max-width: 570px) {
    #profile-page {
        .container {
            padding-left: 30px;
            padding-right: 30px;    
            
            width: auto !important;
        }
    }
}