.dealer .main {
    .tab1 {
        height: 50px;
        background-color: #c8e0ed;
        color: #3a5b77;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .tab2 {
        height: 50px;
        background-color: #3a5b77;
        color: white;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 570px) {
    .dealer .main {
        width: 500px !important;
        margin-left: calc(50% - 250px);

        .search-modal {
            width: 500px;
            left: calc(50% - 250px);
        }
    }
}

@media (max-width: 570px) {
    .dealer .main {
        margin: 0px !important;
        padding: 0px !important;

        .search-modal {
            width: 100%;
            left: 0;
        }
    }
}

.dealer .main {
    text-align: center;
    padding-top: 20px;
    color: white;

    .dashboard {
        margin: 20px;
    }

    .form-group {
        position: relative;
        margin-bottom: 0px;
    }

    .form-label {
        font-size: 14px;
        font-weight: bold;
        color: #3a5b77;
        margin-bottom: 0px;
        padding-left: 5px;
    }
    .form-control {
        border-radius: 3px;
        border: none;
        font-size: 18px;
    }

    .customer-search {
        position: relative;
        #header {
            height: 50px;
            background-color: #3a5b77;
            padding-top: 13px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            #icon {
                position: absolute;
                top: 12px;
                right: 15px;
                width: 25px;
                height: 25px;
                cursor: pointer;
            }
        }
        #form {
            height: 178px;
            background-color: #c8e0ed;
            font-family: Lato;
            padding: 5px 15px;
            text-align: left;
            font-size: 12px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            .form-group {
                margin-top: 7px;
            }

            .mb-18 {
                margin-bottom: 18px;
            }
        }
    }

    .light-bg {
        padding: 32px;
        margin: -32px;
        margin-top: 75px;
        padding-top: 1px;
        border-radius: 4px;
        background-color: #3e596f;
        padding-bottom: 20px;

        .listing-action {
            display: inline-flex;
        }

        @media (max-width: 767px) { 
            .listing-action {
                display: inline-block;
            }
        }
    }

    @media (max-width: 767px) {
        .light-bg {
            background-color: transparent;
        }
    }

    .sales-details {
        margin-top: -58px;

        .main-btn {
            border-radius: 5px;
            margin-bottom: 15px;
        }

        @media (max-width: 767px) {
            .main-btn {
                border-radius: 0px;
                margin-bottom: -5px;
            }
        }

        #box {
            background-color: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #3a5b77;
            padding: 0px 15px;

            .part1 {
                padding: 17px 0px;
                display: flex;
                justify-content: space-between;

                .count {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 15px;
                    letter-spacing: 0.45px;
                    margin-left: 12px;
                }

                @media (max-width: 767px) { 
                    .count {
                        margin-top: 5px;
                    }
                }
                
                #pre-approvals, #incomplete {
                    cursor: pointer;
                    width: 48%;
                    height: 70px;
                    border-radius: 5px;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    font-size: 10px;
                    font-weight: 800;
                    padding: 0px 20px;
                }

                #pre-approvals {
                    background: #6792b6;
                    color: white;
                }

                #incomplete {
                    background: #c8e0ed;
                    color: #3a5b77;

                    .notify-count {
                        position: absolute;
                        margin-top: -33px;
                        background: white;
                        border-radius: 20px;
                    }
                }
            }

            .part2 {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding: 10px 0px;
                border-top: 1px solid #c8e0ed;

                .count {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.22;
                    letter-spacing: 0.45px;
                }

                .icon {
                    width: 62px;
                    cursor: pointer;
                }

                @media (max-width: 767px) {
                    .icon {
                        width: auto;
                    }
                }

                .status {
                    font-size: 10px;
                    font-weight: bold;
                    line-height: 1.2;
                    letter-spacing: 0.25px;
                    margin-top: 8px;
                    width: 62px;
                }
            }
        }
        .action-button {
            cursor: pointer;

            #icon {
                border-bottom-left-radius: 5px;
            }
            #title {
                border-bottom-right-radius: 5px;
            }

            &:hover {

                #icon {
                    background-color: #91e9ff;
                }
                #title {
                    background-color: #00c0e1;
                }
            }
        }
    }

    .action-button {
        display: flex;
        cursor: pointer;
        margin-bottom: 21px;

        #icon {
            width: 57px;
            height: 48px;
            background-color: #c8e0ed;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #title {
            flex: 1;
            height: 48px;
            background-color: #6792b6;
            padding-top: 15px;
            font-size: 14px;
            font-weight: 600;
        }

        &:hover {

            #icon {
                background-color: #91e9ff;
            }
            #title {
                background-color: #00c0e1;
            }
        }
    }

    @media (max-width: 767px) {
        .action-button {
            padding: 0px !important;
        }
    }

    .actions {
        .action-button {
            #icon {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            #title {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
}

.search-modal {
    position: fixed;
    bottom: 0;
    background: white;
    height: 50%;
    width: 100%;

    .status-bar {
        height: 60px;
        width: 100%;
        background: #009e87;
        display: flex;
        padding: 0 38%;

        #status, #action {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;

            .result-count {
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                margin-left: -35px;
            }
        }

        .cancel-button {
            width: 153px;
            height: 37px;
            border-radius: 4px;
            background-color: #c8e0ed;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #3a5b77;
            font-size: 11px;
            font-weight: 900;
        }
    }

    @media (max-width: 1080px) {
        .status-bar {
            padding: 0 18%;
        }
    }

    @media (max-width: 767px) {
        .status-bar {
            padding: 0 3%;
        }
    }


    .search-data {
        color: #3a5b77;
        text-align: left;
        padding: 0px 1%;
        // height: 250px;
        overflow: auto;

        .search-row {
            padding: 16px 24%;
            border-bottom: 1px solid #3d6e9863;
            cursor: pointer;

            .linked-arrow {
                float: right;
                position: sticky;
                transform: translate(0, 10px);
            }

            .customer-details {
                display: inline-flex;
                line-height: 20px;
                margin-top: 4px;

                .customer-name {
                    font-family: Lato;
                    font-size: 19px;
                    font-weight: bold;
                    color: #3a5b77;
                    line-height: 27px;
                }

                .customer-address {
                    font-family: Lato;
                    font-size: 14px;
                    font-weight: 400;
                    color: #3a5b77;
                    line-height: 29px;
                    margin-left: 10px;
                }
            }
        }

        @media (max-width: 767px) {
            .search-row {
                padding: 16px 2%;

                .linked-arrow {
                    transform: translate(0, 22px);
                }

                .customer-details {
                    display: inline-block;

                    .customer-address {
                        margin-left: 0px;
                    }
                }
            }
        }

    }
}

.cmp-info {
    margin-top: 45px;
    font-size: 10px;
    text-align: center;
    color: #6792b6;
}