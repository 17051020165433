.dealer .dealer-footer {
    position: fixed;
    bottom: 0;
    background-color: #009e87;
    width: 100%;
    height: 58px;
    text-align: center;
    padding-top: 10px;

    button {
        width: 150px;
        height: 37px;
        border-radius: 4px;
        background-color: #c8e0ed;
        color: #3a5b77;        
        border: none;
    }

    .btn-request-funding {
        text-align: left;
        padding-left: 5px;
        button {
            width: 153px;
            height: 37px;
            border-radius: 4px;
            background-color: #c8e0ed;
            font-size: 11px;
            font-weight: 900;
            line-height: 1.09;
            letter-spacing: 0.28px;
            text-align: center;
            color: #3a5b77;
        }
    }

    .delivery-date {
        text-align: right;
        padding-right: 5px;
        span {
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
        }
        input {
            display: inline-block;
            padding: 0px;
            width: 91px;
            height: 36px;
            margin-left: 10px;
            font-size: 12px;
            text-align: center;
        }
    }
}